import React, { useEffect, useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import { Avatar, Button } from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import "../font.css";
import client from "../services/client";
import { INITIAL_DATA, URL, USERS } from "../services/services";
import { headersConfig } from "../services/headersConfig";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { useDispatch } from "react-redux";
import { setIsShown } from "../redux/announcementSlice";
import BorderColorIcon from "@material-ui/icons/BorderColor";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    cursor: "pointer",
    fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  gutters: {
    maxWidth: 1220,
    margin: "0 auto",
    width: "100%",
    padding: 12,
  },
}));

export default function PrimarySearchAppBar({
  theme,
  setTheme,
  showSidebar,
  setShowSidebar,
}) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorTerms, setAnchorTerms] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [notifCount, setNotifCount] = useState(0);
  const [announcement, setAnnouncement] = useState(null);
  const [avatar, setAvatar] = useState(null);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();

  const id = localStorage.getItem("userId");
  const isLoggedIn = localStorage.getItem("auth");
  const avatarAvailable = localStorage.getItem("avatar");

  useEffect(() => {
    setTheme(localStorage.getItem("theme"));
    fetchAnnouncement();
  }, []);

  useEffect(() => {
    if (id) {
      client
        .get(INITIAL_DATA, {
          headers: headersConfig,
        })
        .then((res) => {
          setNotifCount(res.data.data.notifications_unread_count);
        });
    }
  }, [id]);

  useEffect(() => {
    if (avatarAvailable) setAvatar(avatarAvailable);
    if (isLoggedIn && !avatarAvailable) {
      fetchUserData();
    }
  }, [isLoggedIn, avatarAvailable]);

  const fetchAnnouncement = () => {
    axios
      .get(
        "https://sheets.googleapis.com/v4/spreadsheets/1BhxsfA-2rhwg9Jj-YqbVKG5ubvNfUhEUwuB7UySWLKs/values/Sheet1?valueRenderOption=FORMATTED_VALUE&key=AIzaSyC1lk5ScudZeefFoIMXvHSux8dxzQQ4EmY"
      )
      .then((response) => {
        if (response?.data?.values[1]) {
          dispatch(setIsShown());
          setAnnouncement(response?.data?.values[1]);
        }
      })
      .catch((error) => console.log(error));
  };

  const fetchUserData = () => {
    client
      .get(`${USERS}/${localStorage.getItem("username")}`)
      .then((res) => {
        const avatar = res?.data?.data?.avatar_thumb_url;
        localStorage.setItem("avatar", avatar);
        setAvatar(avatar);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleToggleTheme = (theme) => {
    localStorage.setItem("theme", theme);
    setTheme(theme);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMenuTermsOpen = Boolean(anchorTerms);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTermsMenuOpen = (event) => {
    setAnchorTerms(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorTerms(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleProfile = () => {
    handleMenuClose();
    window.open(`/profile/${localStorage.getItem("username")}`, "_blank");
  };

  const handleNotification = () => {
    handleMenuClose();
    setNotifCount(0);
    history.push("/notification");
  };

  const handleSettings = () => {
    handleMenuClose();
    history.push("/settings");
  };

  const handleTnC = () => {
    handleMenuClose();
    window.open(`/tnc`, "_blank");
  };

  const handlePrivacy = () => {
    handleMenuClose();
    window.open(`/privacy`, "_blank");
  };

  const handleLogout = () => {
    handleMenuClose();
    localStorage.clear();
    window.location.href = "/login";
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfile}>Profile</MenuItem>
      <MenuItem onClick={handleSettings}>Settings</MenuItem>
      <MenuItem onClick={handleTermsMenuOpen}>Terms &amp; Privacy</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const renderMenuTerms = (
    <Menu
      anchorEl={anchorTerms}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuTermsOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleTnC}>Terms</MenuItem>
      <MenuItem onClick={handlePrivacy}>Privacy</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {isLoggedIn ? (
        <div>
          <MenuItem onClick={handleNotification}>
            <IconButton aria-label="show 11 new notifications" color="inherit">
              <Badge badgeContent={notifCount} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <p>Notifications</p>
          </MenuItem>
          <MenuItem onClick={handleProfileMenuOpen}>
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <Avatar
                src={avatar || AccountCircle}
                style={{ width: 24, height: 24 }}
              />
            </IconButton>
            <p>Profile</p>
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to="/upload"
            onClick={handleMobileMenuClose}
          >
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <AddIcon />
            </IconButton>
            <p>Upload</p>
          </MenuItem>
        </div>
      ) : (
        <div>
          <MenuItem
            component={RouterLink}
            to="/login"
            onClick={handleMobileMenuClose}
          >
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <p>Log in</p>
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to="/signup"
            onClick={handleMobileMenuClose}
          >
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <AssignmentIndIcon />
            </IconButton>
            <p>Signup</p>
          </MenuItem>
        </div>
      )}
      <MenuItem onClick={handleTermsMenuOpen}>
        <IconButton
          aria-label="terms"
          aria-controls="primary-terms-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ListAltIcon />
        </IconButton>
        <p>Terms &amp; Privacy</p>
      </MenuItem>
      <MenuItem
        onClick={() => handleToggleTheme(theme === "light" ? "black" : "light")}
      >
        <IconButton
          aria-label="Toggle light/dark theme"
          aria-controls="primary-toggle-theme"
          aria-haspopup="true"
          color="inherit"
        >
          {theme === "light" ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
        <p>Toggle light/dark theme</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        style={{ position: "fixed" }}
      >
        {announcement && (
          <p
            className="MuiTypography-root jss18 MuiTypography-body2 MuiTypography-noWrap"
            style={{
              color: "white",
              display: "block",
              padding: 8,
              textAlign: "center",
              backgroundColor: "rgb(17, 82, 147)",
              cursor: "pointer",
            }}
            onClick={() => window.open(announcement[2], "_blank").focus()}
          >
            {announcement[1]}
          </p>
        )}
        <Toolbar classes={{ gutters: classes.gutters }}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            className={classes.title}
            variant="h6"
            noWrap
            onClick={() => history.push("/")}
          >
            GAG101
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                history.push(`/search/${e.target[0].value}`);
              }}
            >
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </form>
          </div>
          <Button
            href="https://linktr.ee/gag101"
            rel="noopener noreferrer"
            target="_blank"
            variant="contained"
            color="secondary"
            size="small"
            disableElevation
            startIcon={
              <FavoriteBorderIcon style={isMobile ? { marginRight: -8 } : {}} />
            }
          >
            {!isMobile && " Support Us"}
          </Button>
          <Button
            href="https://gag101.canny.io/"
            rel="noopener noreferrer"
            target="_blank"
            variant="contained"
            color="primary"
            size="small"
            disableElevation
            style={{ marginLeft: 8 }}
            startIcon={
              <BorderColorIcon style={isMobile ? { marginRight: -8 } : {}} />
            }
          >
            {!isMobile && " Feedback & Suggestions"}
          </Button>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {isLoggedIn && (
              <>
                <IconButton
                  aria-label="show new notifications"
                  color="inherit"
                  onClick={handleNotification}
                >
                  <Badge badgeContent={notifCount} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </>
            )}
            <IconButton
              aria-label="toggle light/dark theme"
              onClick={() =>
                handleToggleTheme(theme === "light" ? "black" : "light")
              }
              color="inherit"
            >
              {theme === "light" ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            {isLoggedIn === null ? (
              <>
                <Button component={RouterLink} to="/login" color="inherit">
                  Log in
                </Button>
                <Button
                  component={RouterLink}
                  to="/signup"
                  variant="contained"
                  color="primary"
                  disableElevation
                  style={{ borderRadius: 50 }}
                >
                  Sign up
                </Button>
              </>
            ) : (
              <Button
                component={RouterLink}
                to="/upload"
                variant="contained"
                color="primary"
                disableElevation
                style={{ borderRadius: 50 }}
                startIcon={<AddIcon />}
              >
                Upload
              </Button>
            )}
            {isLoggedIn && (
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar
                  src={avatar || AccountCircle}
                  style={{ width: 24, height: 24 }}
                />
              </IconButton>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <Badge badgeContent={notifCount} color="secondary">
                <Avatar
                  src={avatar || AccountCircle}
                  style={{ width: 24, height: 24 }}
                />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderMenuTerms}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GetAppIcon from "@material-ui/icons/GetApp";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import ReportIcon from "@material-ui/icons/Report";
import client from "../services/client";
import { POSTS, URL } from "../services/services";
import { headersConfig } from "../services/headersConfig";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@material-ui/core";
import ReportDialog from "./ReportDialog";
import BlockDialog from "./BlockDialog";
import QueueIcon from "@material-ui/icons/Queue";
import DeleteIcon from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Block";

const useStyles = makeStyles({
  fullList: {
    width: "auto",
  },
  divider: { height: 2, width: "15%", margin: "auto" },
  loading: { height: 210, display: "flex", justifyContent: "center" },
});

export default function SwipeableTemporaryDrawer({
  downloadLink,
  optionsOpen,
  setOptionsOpen,
  public_uid,
  setDeleted,
  user_id,
  comment_id,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [post, setPost] = useState({});
  const [openReport, setOpenReport] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const userId = localStorage.getItem("userId");

  const ownPost = userId == user_id;

  useEffect(() => {
    if (public_uid) {
      client
        .get(`${POSTS}/${public_uid}`, {
          headers: headersConfig,
        })
        .then((res) => {
          setPost(res.data.data);
        })
        .catch((e) => {
          enqueueSnackbar("Error when fetching details", {
            variant: "warning",
          });
          setOptionsOpen(false);
        });
    }
  }, [public_uid]);

  const copyLink = () => {
    navigator.clipboard.writeText(
      `https://gag101.com/posts/${post.public_uid}`
    );
    setOptionsOpen(false);
  };

  const handleDownload = () => {
    window.open(`${URL}/api/v1/posts/${public_uid}/download`);
  };

  const handleSave = () => {
    let state = "save";
    if (post.is_saved) {
      state = "unsave";
    }
    client
      .patch(`${POSTS}/${post.public_uid}/${state}`, null, {
        headers: headersConfig,
      })
      .then((res) =>
        enqueueSnackbar(res?.data?.message || "Success", {
          variant: "success",
        })
      )
      .catch((e) => {
        enqueueSnackbar(e.response?.data?.errors[0] || "Something went wrong", {
          variant: "warning",
        });
      });
    setOptionsOpen(false);
  };

  const handleRepost = () => {
    client
      .patch(`${POSTS}/${post.public_uid}/mark-as-repost`, null, {
        headers: headersConfig,
      })
      .then((res) =>
        enqueueSnackbar(res?.data?.message || "Success", {
          variant: "success",
        })
      )
      .catch((e) => {
        enqueueSnackbar(e.response?.data?.errors[0] || "Something went wrong", {
          variant: "warning",
        });
      });
    setOptionsOpen(false);
  };

  const handleDelete = () => {
    client
      .delete(`${POSTS}/${post.public_uid}`, {
        headers: headersConfig,
      })
      .then((res) => {
        enqueueSnackbar(res?.data?.message || "Success", {
          variant: "success",
        });
        setDeleted(true);
      })
      .catch((e) => {
        enqueueSnackbar(e.response?.data?.errors[0] || "Something went wrong", {
          variant: "warning",
        });
      });
    setOptionsOpen(false);
  };

  const list = () => (
    <div role="presentation">
      <List>
        <Divider className={classes.divider} />
        {!comment_id && (
          <>
            {" "}
            <ListItem button onClick={copyLink}>
              <ListItemIcon>
                <FileCopyIcon />
              </ListItemIcon>
              <ListItemText primary={"Copy"} />
            </ListItem>
            <ListItem button onClick={handleDownload}>
              <ListItemIcon>
                <GetAppIcon />
              </ListItemIcon>
              <ListItemText primary={"Download"} />
            </ListItem>
            <ListItem button onClick={handleSave}>
              <ListItemIcon>
                {post.is_saved ? <BookmarkIcon /> : <BookmarkBorderIcon />}
              </ListItemIcon>
              <ListItemText primary={post.is_saved ? "Unsave" : "Save"} />
            </ListItem>
          </>
        )}
        {!ownPost ? (
          <>
            {!comment_id && (
              <ListItem button onClick={handleRepost}>
                <ListItemIcon>
                  <QueueIcon />
                </ListItemIcon>
                <ListItemText primary={"Mark as repost"} />
              </ListItem>
            )}
            <ListItem button onClick={() => setOpenReport(true)}>
              <ListItemIcon>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText primary={"Report"} />
            </ListItem>
            <ListItem button onClick={() => setOpenBlock(true)}>
              <ListItemIcon>
                <BlockIcon />
              </ListItemIcon>
              <ListItemText primary={"Block"} />
            </ListItem>
          </>
        ) : (
          <ListItem button onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary={"Delete"} />
          </ListItem>
        )}
      </List>
      <Divider />
    </div>
  );

  return (
    <>
      <SwipeableDrawer
        anchor={"bottom"}
        open={optionsOpen}
        onClose={() => setOptionsOpen(false)}
        onOpen={() => setOptionsOpen(true)}
        PaperProps={{ square: false }}
      >
        {Object.keys(post).length === 0 && !comment_id ? (
          <div className={classes.loading}>
            <CircularProgress
              color="secondary"
              size={20}
              style={{ alignSelf: "center" }}
            />
          </div>
        ) : (
          list("bottom")
        )}
      </SwipeableDrawer>
      <ReportDialog
        openReport={openReport}
        setOpenReport={setOpenReport}
        public_uid={public_uid}
        setOptionsOpen={setOptionsOpen}
        comment_id={comment_id}
      />
      <BlockDialog
        openBlock={openBlock}
        setOpenBlock={setOpenBlock}
        user_id={user_id}
        setOptionsOpen={setOptionsOpen}
      />
    </>
  );
}

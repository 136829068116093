import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import client from "../services/client";
import { headersConfig } from "../services/headersConfig";
import { COMMENTS } from "../services/services";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function DeleteDialog({
  openDelete,
  setOpenDelete,
  id,
  setDeleted,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = () => {
    setOpenDelete(false);
    client
      .delete(`${COMMENTS}/${id}`, {
        headers: {
          ...headersConfig,
        },
      })
      .then((res) => {
        enqueueSnackbar(res?.data?.message || "Success", {
          variant: "success",
        });
        setDeleted(true);
      })
      .catch((e) => {
        enqueueSnackbar(e.response?.data?.errors[0] || "Something went wrong", {
          variant: "warning",
        });
      });
  };

  return (
    <>
      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="reason-dialog"
      >
        <DialogTitle id="reason-dialog">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

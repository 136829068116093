import React, { lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Top from "./pages/top";
import Leaderboard from "./pages/leaderboard";

const Home = lazy(() => import("./pages/home"));
const SignUp = lazy(() => import("./pages/signup"));
const Settings = lazy(() => import("./pages/settings"));
const Profile = lazy(() => import("./pages/profile"));
const Notification = lazy(() => import("./pages/notification"));
const Upload = lazy(() => import("./pages/upload"));
const Details = lazy(() => import("./pages/details"));
const Trending = lazy(() => import("./pages/trending"));
const RecentComment = lazy(() => import("./pages/recent_comment"));
const Section = lazy(() => import("./pages/section"));
const Search = lazy(() => import("./pages/search"));
const Tag = lazy(() => import("./pages/tag"));
const Forgot = lazy(() => import("./pages/forgot"));
const Reset = lazy(() => import("./pages/reset"));
const Login = lazy(() => import("./pages/login"));
const Tnc = lazy(() => import("./pages/tnc"));
const Privacy = lazy(() => import("./pages/privacy"));

function PrivateRoute({ component: Component, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => {
        return localStorage.getItem("auth") ? children : <Redirect to="/404" />;
      }}
    />
  );
}

function NotFound() {
  return (
    <Grid container>
      <Grid item>
        <h2>404</h2>
        <img
          src="https://i.ytimg.com/vi/m_tP14GaTyE/maxresdefault.jpg"
          width={340}
          alt="404"
        />
      </Grid>
    </Grid>
  );
}

export default function MainRouter() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/trending">
        <Trending />
      </Route>
      <Route path="/recent-comment">
        <RecentComment />
      </Route>
      <Route path="/top/:type">
        <Top />
      </Route>
      <Route path="/section">
        <Section />
      </Route>
      <Route path="/search">
        <Search />
      </Route>
      <Route path="/tag">
        <Tag />
      </Route>
      <Route path="/forgot-password">
        <Forgot />
      </Route>
      <Route path="/reset-password">
        <Reset />
      </Route>
      <Route
        exact
        path={["/posts/:postId?", "/posts/:postId?/threads/:threadId?"]}
      >
        <Details />
      </Route>
      <Route path="/signup">
        <SignUp />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/profile">
        <Profile />
      </Route>
      <Route path="/tnc">
        <Tnc />
      </Route>
      <Route path="/privacy">
        <Privacy />
      </Route>
      <Route path="/leaderboard">
        <Leaderboard />
      </Route>
      <Route path="/404">
        <NotFound />
      </Route>
      <PrivateRoute>
        <Route path="/settings/:tabSelected?">
          <Settings />
        </Route>
        <Route path="/notification">
          <Notification />
        </Route>
        <Route path="/upload">
          <Upload />
        </Route>
      </PrivateRoute>
    </Switch>
  );
}

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GifPicker from "gif-picker-react";
import { useMediaQuery, useTheme } from "@material-ui/core";

export default function TenorDialog({ open, onGifClick, onClose }) {
  const muiTheme = useTheme();
  const isDesktop = useMediaQuery(muiTheme.breakpoints.up("md"));

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="form-dialog-title">Add GIF</DialogTitle>
      <DialogContent>
        <GifPicker
          tenorApiKey={"AIzaSyDjOtuRAvTu1vGopXFIJxigvHmq9eejFVw"}
          onGifClick={onGifClick}
          width={isDesktop ? 350 : 280}
        />
      </DialogContent>
    </Dialog>
  );
}

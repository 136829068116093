import {
  Button,
  Grid,
  Typography,
  Avatar,
  IconButton,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useEffect, useState } from "react";
import client from "../services/client";
import { COMMENTS, URL } from "../services/services";
import { useSnackbar } from "notistack";
import { headersConfig } from "../services/headersConfig";
import ReplyBox from "./ReplyBox";
import Reply from "./Reply";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDialog from "./DeleteDialog";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CodeIcon from "@material-ui/icons/Code";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import BottomDrawer from "./BottomDrawer";
import { useParams } from "react-router-dom";
import useDynamicRefs from "use-dynamic-refs";
import GetAppIcon from "@material-ui/icons/GetApp";
import * as linkify from "linkifyjs";
import "linkify-plugin-mention";
import Linkify from "linkify-react";

const options = {
  formatHref: {
    mention: (href) => "https://gag101.com/profile" + href,
  },
};

const useStyles = makeStyles(() => ({
  link: {
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
    },
  },
}));

export default function Comment({ comment, postUserId, loadingThreads }) {
  const [isUpvoted, setIsUpvoted] = useState(false);
  const [isDownvoted, setIsDownvoted] = useState(false);
  const [upvoteCount, setUpvoteCount] = useState(0);
  const [downvoteCount, setDownvoteCount] = useState(0);
  const [openReply, setOpenReply] = useState(false);
  const [replies, setReplies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingReply, setLoadingReply] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const userId = localStorage.getItem("userId");

  const { threadId } = useParams();
  const [currentPreviousPage, setCurrentPreviousPage] = useState(0);
  const [loadingPrevReply, setLoadingPrevReply] = useState(false);
  const [totalPage, setTotalPage] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [getRef, setRef] = useDynamicRefs();

  useEffect(() => {
    setUpvoteCount(comment.upvotes_count);
    setDownvoteCount(comment.downvotes_count);
    setIsUpvoted(comment.is_upvoted);
    setIsDownvoted(comment.is_downvoted);
  }, [comment]);

  useEffect(() => {
    if (comment?.descendants) {
      setReplies(comment?.descendants);
      setCurrentPreviousPage(comment?.currentPage);
      setCurrentPage(comment?.currentPage);
      setTotalPage(comment?.totalPageCount);
    }
  }, [comment?.descendants]);

  useEffect(() => {
    if (!loadingThreads) {
      let timeout;
      let id;

      timeout = setTimeout(() => {
        setIsFocused(true);
        id = getRef("scrollRef");
        id?.current?.scrollIntoView({ behavior: "smooth" });
        timeout = setTimeout(() => {
          setIsFocused(false);
        }, 2000);
      }, 250);
    }
  }, [loadingThreads, getRef]);

  const handleUpvote = (id) => {
    const prevUpvote = upvoteCount;
    const prevDownvote = downvoteCount;
    if (isDownvoted) {
      setDownvoteCount((prevState) => prevState - 1);
      setIsDownvoted(false);
    }
    if (isUpvoted) {
      setUpvoteCount((prevState) => prevState - 1);
      setIsUpvoted(false);
      client
        .patch(`${COMMENTS}/${id}/unvote`, null, { headers: headersConfig })
        .catch((e) => {
          setUpvoteCount(prevUpvote);
          setIsUpvoted(true);
          enqueueSnackbar(e.response?.data?.message || "Something went wrong", {
            variant: "warning",
          });
        });
      return;
    }
    setUpvoteCount((prevState) => prevState + 1);
    setIsUpvoted(true);
    client
      .patch(`${COMMENTS}/${id}/upvote`, null, { headers: headersConfig })

      .catch((e) => {
        if (isDownvoted) {
          setDownvoteCount(prevDownvote);
          setIsDownvoted(true);
        }
        setUpvoteCount(prevUpvote);
        setIsUpvoted(false);
        enqueueSnackbar(e.response?.data?.message || "Something went wrong", {
          variant: "warning",
        });
      });
  };

  const handleDownvote = (id) => {
    const prevUpvote = upvoteCount;
    const prevDownvote = downvoteCount;
    if (isUpvoted) {
      setUpvoteCount((prevState) => prevState - 1);
      setIsUpvoted(false);
    }
    if (isDownvoted) {
      setDownvoteCount((prevState) => prevState - 1);
      setIsDownvoted(false);
      client
        .patch(`${COMMENTS}/${id}/unvote`, null, { headers: headersConfig })
        .catch((e) => {
          setDownvoteCount(prevDownvote);
          setIsDownvoted(true);
          enqueueSnackbar(e.response?.data?.message || "Something went wrong", {
            variant: "warning",
          });
        });
      return;
    }
    setDownvoteCount((prevState) => prevState + 1);
    setIsDownvoted(true);
    client
      .patch(`${COMMENTS}/${id}/downvote`, null, { headers: headersConfig })
      .catch((e) => {
        if (isUpvoted) {
          setUpvoteCount(prevUpvote);
          setIsUpvoted(true);
        }
        setDownvoteCount(prevDownvote);
        setIsDownvoted(false);
        enqueueSnackbar(e.response?.data?.message || "Something went wrong", {
          variant: "warning",
        });
      });
  };

  const fetchReplies = () => {
    setCurrentPage((prevState) => prevState + 1);
    setLoadingReply(true);
    client
      .get(`${COMMENTS}/${comment?.id}/descendants`, {
        params: {
          page: currentPage,
        },
        headers: headersConfig,
      })
      .then((res) => {
        setReplies(res.data.data);
        setLoadingReply(false);
      })
      .catch(() => {
        enqueueSnackbar("Error when fetching comments sections", {
          variant: "warning",
        });
        setLoadingReply(false);
      });
  };

  const fetchPrevReplies = () => {
    setCurrentPreviousPage((prevState) => prevState - 1);
    setLoadingPrevReply(true);
    client
      .get(`${COMMENTS}/${comment?.id}/descendants`, {
        params: {
          page: currentPreviousPage - 1,
        },
      })
      .then((res) => {
        setReplies((prevState) => [...res.data.data, ...prevState]);
        setLoadingPrevReply(false);
      })
      .catch(() => {
        enqueueSnackbar("Error when fetching replies sections", {
          variant: "warning",
        });
        setLoadingPrevReply(false);
      });
  };

  const fetchMoreReplies = () => {
    setCurrentPage((prevState) => prevState + 1);
    setLoadingReply(true);
    client
      .get(`${COMMENTS}/${comment?.id}/descendants`, {
        params: {
          page: currentPage,
        },
      })
      .then((res) => {
        setReplies((prevState) => [...prevState, ...res.data.data]);
        setLoadingReply(false);
      })
      .catch(() => {
        enqueueSnackbar("Error when fetching replies sections", {
          variant: "warning",
        });
        setLoadingReply(false);
      });
  };

  const handleOpenProfile = () => {
    window.open(`/profile/${comment?.user?.username}`, "_blank").focus();
  };

  const handleDownload = () => {
    window.open(`${URL}/api/v1/comments/${comment?.id}/download`);
  };

  if (deleted)
    return (
      <div
        style={{
          backgroundColor: "#999",
          marginBottom: 20,
          padding: 4,
        }}
      >
        Comment deleted
      </div>
    );

  return (
    <Grid
      direction="column"
      container
      style={{
        paddingLeft: isDesktop ? 0 : 16,
        paddingRight: isDesktop ? 0 : 16,
      }}
    >
      <Grid item style={{ marginBottom: 20 }}>
        <Grid
          container
          direction="row"
          style={{
            backgroundColor:
              comment?.id == threadId && isFocused && "rgba(217, 157, 85, 0.3)",
          }}
        >
          <Avatar
            src={comment?.user?.avatar_thumb_url || AccountCircle}
            style={{ marginRight: 8, marginBottom: 8 }}
            className={classes.link}
            onClick={handleOpenProfile}
          />
          <Grid
            item
            style={{
              maxWidth: 600,
              flex: 1,
            }}
          >
            <Grid container direction="column">
              <div
                ref={setRef(
                  comment?.id == threadId ? "scrollRef" : `normalRef`
                )}
              >
                <Typography
                  variant="body1"
                  display="inline"
                  style={{
                    fontWeight: 700,
                  }}
                  className={classes.link}
                  onClick={handleOpenProfile}
                >
                  {comment?.user?.username || "Username"}
                </Typography>
                <Typography
                  variant="caption"
                  display="inline"
                  style={{ color: "#999" }}
                >
                  {" "}
                  · {moment(comment.created_at).fromNow()}{" "}
                </Typography>
                {comment?.user?.id === postUserId && (
                  <Typography
                    variant="caption"
                    display="inline"
                    style={{
                      backgroundColor: "#eb6709",
                      fontWeight: 700,
                      color: "white",
                      padding: "0 4px",
                    }}
                  >
                    OP
                  </Typography>
                )}
                {(comment?.user?.username === "codesmith" ||
                  comment?.user?.username === "titoasdf") && (
                  <Typography
                    variant="caption"
                    display="inline"
                    style={{
                      backgroundColor: "#fca503",
                      fontWeight: 700,
                      color: "white",
                      padding: "0 4px",
                    }}
                  >
                    DEV <CodeIcon style={{ fontSize: 8 }} />
                  </Typography>
                )}
                {comment?.user?.badge_name && (
                  <Typography
                    variant="caption"
                    display="inline"
                    style={{
                      backgroundColor: "gray",
                      fontWeight: 700,
                      color:
                        comment?.user?.badge_name === "VVIP" ||
                        comment?.user?.badge_name === "VIP"
                          ? "orange"
                          : "white",
                      padding: "0 4px",
                    }}
                  >
                    {comment?.user?.badge_name}
                  </Typography>
                )}
              </div>

              <Typography
                variant="body2"
                gutterBottom
                style={{
                  whiteSpace: "pre-line",
                  wordBreak: "break-word",
                }}
              >
                <Linkify options={options}>{comment.body}</Linkify>
              </Typography>
              {comment.image_url && (
                <img
                  src={comment.image_url}
                  style={{ maxWidth: "100%", width: 400, height: "auto" }}
                />
              )}
            </Grid>
          </Grid>
          {userId == comment?.user?.id ? (
            <IconButton
              size="small"
              aria-label="options"
              onClick={() => setOpenDelete(true)}
              style={{ height: 30 }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              aria-label="options"
              onClick={() => setOptionsOpen(true)}
              style={{ height: 30 }}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          )}
          <Grid container direction="row" alignContent="space-around">
            <Button
              startIcon={
                <ArrowUpwardIcon
                  style={{ color: isUpvoted ? "#07f" : "gray" }}
                />
              }
              variant="outlined"
              style={{
                color: isUpvoted ? "#07f" : "gray",
                fontWeight: "bold",
                borderColor: "transparent",
              }}
              onClick={() => handleUpvote(comment.id)}
            >
              {upvoteCount}
            </Button>
            <Button
              startIcon={
                <ArrowDownwardIcon
                  style={{
                    color: isDownvoted ? "#07f" : "gray",
                  }}
                />
              }
              variant="outlined"
              style={{
                color: isDownvoted ? "#07f" : "gray",
                fontWeight: "bold",
                borderColor: "transparent",
              }}
              onClick={() => handleDownvote(comment.id)}
            >
              {downvoteCount}
            </Button>
            <Button
              variant="outlined"
              style={{
                color: "gray",
                fontWeight: "bold",
                borderColor: "transparent",
              }}
              onClick={() => setOpenReply(true)}
            >
              Reply
            </Button>
            {comment?.image_url && !comment?.image_url.includes("tenor") && (
              <IconButton
                size="small"
                aria-label="download"
                onClick={() => handleDownload()}
              >
                <GetAppIcon fontSize="small" />
              </IconButton>
            )}
          </Grid>
          {openReply && (
            <div style={{ marginLeft: 40, flex: 1 }}>
              <ReplyBox
                commentId={comment.id}
                postId={comment.post_id}
                userName={comment.user.username}
              />
            </div>
          )}
        </Grid>
        {loadingThreads && <CircularProgress size={22} />}
        {comment.descendants_count > 0 && replies.length === 0 && (
          <Button
            size="small"
            style={{
              margin: 8,
              color: "gray",
              fontWeight: "bold",
              marginLeft: 40,
            }}
            onClick={fetchReplies}
            disabled={loadingReply}
          >
            {loadingReply ? (
              <CircularProgress size={22} />
            ) : (
              `View ${comment.descendants_count} replies`
            )}
          </Button>
        )}
        {((currentPreviousPage > 1 && currentPreviousPage <= currentPage) ||
          loadingPrevReply) && (
          <Button
            size="small"
            style={{
              margin: 8,
              color: "gray",
              fontWeight: "bold",
              marginLeft: 40,
            }}
            onClick={fetchPrevReplies}
            disabled={loadingReply}
          >
            {loadingPrevReply ? (
              <CircularProgress size={22} />
            ) : (
              "View previous replies"
            )}
          </Button>
        )}
        {replies.length > 0 &&
          replies.map((reply, index) => (
            <div
              style={{
                marginLeft: 40,
                backgroundColor:
                  reply?.id == threadId &&
                  isFocused &&
                  "rgba(217, 157, 85, 0.3)",
              }}
              key={index}
              ref={setRef(
                reply?.id == threadId ? "scrollRef" : `random${index}`
              )}
            >
              <Reply comment={reply} postUserId={postUserId} />
            </div>
          ))}
        {comment.descendants_count > replies.length &&
          replies.length !== 0 &&
          totalPage !== currentPage && (
            <Button
              size="small"
              style={{
                margin: 8,
                color: "gray",
                fontWeight: "bold",
                marginLeft: 40,
              }}
              onClick={fetchMoreReplies}
              disabled={loadingReply}
            >
              {loadingReply ? (
                <CircularProgress size={22} />
              ) : (
                "View more replies"
              )}
            </Button>
          )}
      </Grid>
      <DeleteDialog
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        id={comment?.id}
        setDeleted={setDeleted}
      />
      {optionsOpen && (
        <BottomDrawer
          optionsOpen={optionsOpen}
          setOptionsOpen={setOptionsOpen}
          user_id={comment?.user?.id}
          comment_id={comment?.id}
        />
      )}
    </Grid>
  );
}

import React from "react";
import Drawer from "@material-ui/core/Drawer";

export default function TemporaryDrawer({
  showDrawer,
  setShowDrawer,
  children,
}) {
  return (
    <div>
      <Drawer
        anchor={"left"}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        {children}
      </Drawer>
    </div>
  );
}

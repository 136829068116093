import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import StarIcon from "@material-ui/icons/Star";
import Divider from "@material-ui/core/Divider";
import HomeIcon from "@material-ui/icons/Home";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import CommentIcon from "@material-ui/icons/Comment";
import { ListItemIcon, Typography } from "@material-ui/core";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SECTIONS, USERS } from "../services/services";
import client from "../services/client";
import { useSnackbar } from "notistack";
import { headersConfig } from "../services/headersConfig";
import { useDispatch } from "react-redux";
import { show } from "../redux/modalSlice";
import TimelineIcon from "@material-ui/icons/Timeline";
import ClearIcon from "@material-ui/icons/Clear";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FilterDialog from "./FilterDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 240,
    marginRight: 20,
    display: "none",
    height: "100%",
    overflow: "auto",
    paddingBottom: 100,
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": { width: 0 },
    [theme.breakpoints.up("md")]: {
      marginTop: 72,
      display: "block",
      position: "fixed",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  title: {
    display: "block",
    marginLeft: 16,
    fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    cursor: "pointer",
  },
  rootIcon: {
    minWidth: 30,
  },
  primaryTitle: {
    color: "#999",
    fontWeight: "bold",
  },
}));

export default function SideBar({ display, setShowSidebar }) {
  const classes = useStyles();
  const [favorites, setFavorites] = useState([]);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [sections, setSections] = useState([]);
  const [filters, setFilters] = useState([]);
  const [userData, setUserData] = useState(null);
  const [filterLimit, setFilterLimit] = useState(2);
  const filterLimitRef = useRef(2);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const id = localStorage.getItem("userId");

  useEffect(() => {
    if (id) {
      fetchUserData();
    }
  }, [id]);

  useEffect(() => {
    const getBadge = () => {
      const badges = userData.badges;
      var vvip = badges.find((obj) => {
        return obj.name === "VVIP";
      });
      var vip = badges.find((obj) => {
        return obj.name === "VIP";
      });
      if (vvip) {
        setFilterLimit(5);
        filterLimitRef.current = 5;
        return;
      }
      if (vip) {
        setFilterLimit(3);
        filterLimitRef.current = 3;
        return;
      }
    };

    if (userData) getBadge();
  }, [userData]);

  useEffect(() => {
    client
      .get(SECTIONS, { headers: headersConfig })
      .then((res) => {
        const data = res.data.data;
        setSections(data);

        let sections = [];
        let favorites = [];
        let filters = [];
        for (var i = 0; i < data.length; i++) {
          if (data[i].is_favorited === true) {
            favorites.push(data[i]);
          } else if (data[i].is_filtered === true) {
            filters.push(data[i]);
          } else {
            sections.push(data[i]);
          }
        }
        setSections(sections);
        setFavorites(favorites);
        setFilters(filters);
      })
      .catch((e) =>
        enqueueSnackbar("Error when fetching sections", {
          variant: "warning",
        })
      );
  }, []);

  const fetchUserData = () => {
    client
      .get(`${USERS}/${localStorage.getItem("username")}`)
      .then((res) => {
        setUserData(res?.data?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleListItemClick = (event, value, isSection) => {
    if (isSection) {
      history.push(`/section/${value}`);
    } else {
      history.push(`/${value}`);
    }
    if (setShowSidebar) {
      setShowSidebar(false);
    }
    window.scrollTo(0, 0);
  };

  const handleUnFavoriteClick = (value) => {
    const prevSec = JSON.parse(JSON.stringify(sections));
    const prevFav = JSON.parse(JSON.stringify(favorites));
    const newFav = favorites.filter((item) => item.id !== value.id);
    setFavorites(newFav);
    setSections((prevState) => [...prevState, value]);
    client
      .patch(`${SECTIONS}/${value.slug}/unfavorite`, null, {
        headers: headersConfig,
      })
      .catch((e) => {
        enqueueSnackbar(e.response?.data?.errors[0] || "Something went wrong", {
          variant: "warning",
        });
        setFavorites(prevFav);
        setSections(prevSec);
      });
  };

  const handleFavoriteClick = (value) => {
    if (!localStorage.getItem("userId")) {
      dispatch(show());
      return;
    }
    const prevSec = JSON.parse(JSON.stringify(sections));
    const prevFav = JSON.parse(JSON.stringify(favorites));
    const newFav = sections.filter((item) => item.id !== value.id);
    setSections(newFav);
    setFavorites((prevState) => [...prevState, value]);
    client
      .patch(`${SECTIONS}/${value.slug}/favorite`, null, {
        headers: headersConfig,
      })
      .catch((e) => {
        enqueueSnackbar(e.response?.data?.errors[0] || "Something went wrong", {
          variant: "warning",
        });
        setFavorites(prevFav);
        setSections(prevSec);
      });
  };

  const handleUnFilterClick = (value) => {
    setLoadingFilter(true);
    const prevSec = JSON.parse(JSON.stringify(sections));
    const prevFav = JSON.parse(JSON.stringify(filters));
    const newFilters = filters.filter((item) => item.id !== value.id);
    setFilters(newFilters);
    setSections((prevState) => [...prevState, value]);
    client
      .patch(`${SECTIONS}/${value.slug}/unfilter`, null, {
        headers: headersConfig,
      })
      .then(() => window.location.reload())
      .catch((e) => {
        enqueueSnackbar(
          e?.response?.data?.errors?.[0] || "Something went wrong",
          {
            variant: "warning",
          }
        );
        setFilters(prevFav);
        setSections(prevSec);
        setLoadingFilter(false);
      });
  };

  const handleFiltersClick = (value) => {
    if (!localStorage.getItem("userId")) {
      dispatch(show());
      return;
    }
    if (filters?.length >= filterLimitRef?.current) {
      enqueueSnackbar("You have reached the maximum filter limit", {
        variant: "info",
      });
      return;
    }
    setLoadingFilter(true);
    const prevSec = JSON.parse(JSON.stringify(sections));
    const prevFav = JSON.parse(JSON.stringify(favorites));
    const newFav = sections.filter((item) => item.id !== value.id);
    setSections(newFav);
    setFilters((prevState) => [...prevState, value]);
    client
      .patch(`${SECTIONS}/${value.slug}/filter`, null, {
        headers: headersConfig,
      })
      .then(() => window.location.reload())
      .catch((e) => {
        enqueueSnackbar(
          e?.response?.data?.errors?.[0] || "Something went wrong",
          {
            variant: "warning",
          }
        );
        setFilters(prevFav);
        setSections(prevSec);
        setLoadingFilter(false);
      });
  };

  return (
    <List className={classes.root} style={{ display }}>
      <Typography
        className={classes.title}
        variant="h6"
        noWrap
        onClick={() => {
          if (setShowSidebar) {
            setShowSidebar(false);
          }
          history.push("/");
        }}
      >
        GAG101
      </Typography>
      <ListItem button={false} dense>
        <ListItemText
          primary="GAG101"
          classes={{ primary: classes.primaryTitle }}
        />
      </ListItem>
      <ListItem
        button
        dense
        component={Link}
        to="/"
        selected={location.pathname === "/"}
        onClick={(event) => handleListItemClick(event, "", false)}
      >
        <ListItemIcon classes={{ root: classes.rootIcon }}>
          <HomeIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Home" />
        <ListItemSecondaryAction />
      </ListItem>
      <ListItem
        button
        dense
        component={Link}
        to="/trending"
        selected={location.pathname === "/trending"}
        onClick={(event) => handleListItemClick(event, "trending", false)}
      >
        <ListItemIcon classes={{ root: classes.rootIcon }}>
          <TrendingUpIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Trending" />
        <ListItemSecondaryAction />
      </ListItem>
      <ListItem
        button
        dense
        component={Link}
        to="/recent-comment"
        selected={location.pathname === "/recent-comment"}
        onClick={(event) => handleListItemClick(event, "recent-comment", false)}
      >
        <ListItemIcon classes={{ root: classes.rootIcon }}>
          <CommentIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Recent Comment" />
        <ListItemSecondaryAction />
      </ListItem>
      <ListItem
        button
        dense
        component={Link}
        to="/top/daily"
        selected={location.pathname === "/top/daily"}
        onClick={(event) => handleListItemClick(event, "top/daily", false)}
      >
        <ListItemIcon classes={{ root: classes.rootIcon }}>
          <TimelineIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Top Daily" />
        <ListItemSecondaryAction />
      </ListItem>
      <ListItem
        button
        dense
        component={Link}
        to="/top/weekly"
        selected={location.pathname === "/top/weekly"}
        onClick={(event) => handleListItemClick(event, "top/weekly", false)}
      >
        <ListItemIcon classes={{ root: classes.rootIcon }}>
          <TimelineIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Top Weekly" />
        <ListItemSecondaryAction />
      </ListItem>
      <ListItem
        button
        dense
        component={Link}
        to="/top/monthly"
        selected={location.pathname === "/top/monthly"}
        onClick={(event) => handleListItemClick(event, "top/monthly", false)}
      >
        <ListItemIcon classes={{ root: classes.rootIcon }}>
          <TimelineIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Top Monthly" />
        <ListItemSecondaryAction />
      </ListItem>
      <ListItem
        button
        dense
        component={Link}
        to="/leaderboard"
        selected={location.pathname === "/leaderboard"}
        onClick={(event) => handleListItemClick(event, "leaderbaord", false)}
        style={{
          backgroundImage: `linear-gradient(135deg, #FF0076, #590FB7)`,
        }}
      >
        <ListItemIcon classes={{ root: classes.rootIcon }}>🏆</ListItemIcon>
        <ListItemText primary="Leaderboard" />
        <ListItemSecondaryAction />
      </ListItem>
      {favorites.length > 0 && (
        <>
          <Divider component="li" />
          <ListItem button={false} dense>
            <ListItemText
              primary="Favorites"
              classes={{ primary: classes.primaryTitle }}
            />
          </ListItem>
        </>
      )}
      {favorites.map((value) => {
        const labelId = `checkbox-list-label-${value.name}`;

        return (
          <ListItem
            key={value.id}
            dense
            button
            component={Link}
            to={`/section/${value.slug}`}
            selected={location.pathname === `/section/${value.slug}`}
            onClick={(event) => handleListItemClick(event, value.slug, true)}
          >
            <ListItemIcon classes={{ root: classes.rootIcon }}>
              <img
                src={value.thumbnail_url}
                width="24"
                height="24"
                alt={value.name}
              />
            </ListItemIcon>
            <ListItemText
              id={labelId}
              primary={`${value.name}`}
              style={{ marginRight: 20 }}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="favorite"
                size="small"
                onClick={() => handleUnFavoriteClick(value)}
              >
                <StarIcon fontSize="small" style={{ color: "orange" }} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
      {userData && filters?.length > 0 && (
        <>
          <Divider component="li" />
          <ListItem button={false} dense>
            <ListItemText
              primary="Filtered"
              classes={{ primary: classes.primaryTitle }}
            />
            <ListItemSecondaryAction style={{ display: "flex" }}>
              <ListItemText
                primary={`(${filters?.length}/${filterLimit})`}
                classes={{ primary: classes.primaryTitle }}
              />
              <IconButton
                edge="end"
                aria-label="comments"
                size="small"
                onClick={() => setOpenFilterDialog(true)}
              >
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </>
      )}
      {userData &&
        filters.map((value) => {
          const labelId = `checkbox-list-label-${value.name}`;

          return (
            <ListItem
              key={value.id}
              dense
              button
              component={Link}
              to={`/section/${value.slug}`}
              selected={location.pathname === `/section/${value.slug}`}
              onClick={(event) => handleListItemClick(event, value.slug, true)}
            >
              <ListItemIcon classes={{ root: classes.rootIcon }}>
                <img
                  src={value.thumbnail_url}
                  width="24"
                  height="24"
                  alt={value.name}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${value.name}`}
                style={{ marginRight: 20 }}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="favorite"
                  size="small"
                  onClick={() => handleUnFilterClick(value)}
                  disabled={loadingFilter}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      <Divider component="li" />
      <ListItem button={false} dense>
        <ListItemText
          primary="Popular"
          classes={{ primary: classes.primaryTitle }}
        />
      </ListItem>
      {sections.map((value, index) => {
        const labelId = `checkbox-list-label-${value.name}`;

        return (
          <ListItem
            key={value.id}
            dense
            button
            component={Link}
            to={`/section/${value.slug}`}
            selected={location.pathname === `/section/${value.slug}`}
            onClick={(event) => handleListItemClick(event, value.slug, true)}
          >
            <ListItemIcon classes={{ root: classes.rootIcon }}>
              <img
                src={value.thumbnail_url}
                width="24"
                height="24"
                alt={value.name}
              />
            </ListItemIcon>
            <ListItemText
              id={labelId}
              primary={`${value.name}`}
              style={{ marginRight: 20 }}
            />
            {userData && (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="comments"
                  size="small"
                  onClick={() => handleFiltersClick(value)}
                  disabled={loadingFilter}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="comments"
                  size="small"
                  onClick={() => handleFavoriteClick(value)}
                >
                  <StarBorderIcon fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
      })}
      <FilterDialog
        openFilterDialog={openFilterDialog}
        setOpenFilterDialog={setOpenFilterDialog}
      />
    </List>
  );
}

import axios from "axios";
import { BASE_URL } from "./services";

const client = axios.create({
  baseURL: BASE_URL,
});

client.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status == 401) {
    localStorage.removeItem("auth");
  }

  return Promise.reject(error);
});

export default client;

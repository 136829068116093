import { createSlice } from "@reduxjs/toolkit";

export const announcementSlice = createSlice({
  name: "announcement",
  initialState: {
    isShown: false,
  },
  reducers: {
    setIsShown: (state) => {
      state.isShown = true;
    },
  },
});

export const { setIsShown } = announcementSlice.actions;

export default announcementSlice.reducer;

import React from "react";
import ReactDOM from "react-dom";
// import CacheBuster from 'react-cache-buster';
import "./index.css";
import App from "./App";
import Loader from "./components/Loader";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RECAPTCHA_SITE_KEY_V3 } from "./services/services";
import { headersConfig } from "./services/headersConfig";
import { URL } from "./services/services";
import ahoy from "ahoy.js";
import { version } from "../package.json";

const isProduction = process.env.NODE_ENV === "production";

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY_V3}>
      {/* <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      > */}
      <App />
      {/* </CacheBuster> */}
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

ahoy.configure({
  urlPrefix: URL,
  visitsUrl: "/ahoy/visits",
  eventsUrl: "/ahoy/events",
  page: null,
  platform: "Web",
  useBeacon: true,
  startOnReady: true,
  trackVisits: true,
  cookies: true,
  cookieDomain: null,
  headers: headersConfig,
  visitParams: {},
  withCredentials: false,
  visitDuration: 30, // 4 hours
  visitorDuration: 2 * 365 * 24 * 60, // 2 years
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

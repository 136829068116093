import PostList from "../../components/PostList";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Top() {
  const { type } = useParams();
  const [renderScreen, setRenderScreen] = useState(false);

  useEffect(() => {
    if (type) {
      setRenderScreen(false);
      setTimeout(() => {
        setRenderScreen(true);
      }, 100);
    }
  }, [type]);

  const generateParams = () => {
    if (type === "daily") return { top_daily: 1 };
    if (type === "weekly") return { top_weekly: 1 };
    if (type === "monthly") return { top_monthly: 1 };
  };

  const generateTitle = () => {
    if (type === "daily") return "Top Daily | GAG101";
    if (type === "weekly") return "Top Weekly | GAG101";
    if (type === "monthly") return "Top Monthly | GAG101";
  };

  if (!renderScreen) return null;

  return (
    <PostList
      params={generateParams()}
      pageTitle={`${generateTitle()} | GAG101`}
      pageDesc={`Top ${type} Memes and Rage Comics`}
    />
  );
}

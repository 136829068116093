import { Adsense } from "@ctrl/react-adsense";
import { Divider, Grid, Typography } from "@material-ui/core";

export default function AdSquare() {
  return (
    <Grid
      item
      style={{
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
      }}
    >
      <Typography
        style={{
          textAlign: "center",
          display: "block",
          marginBottom: 8,
          fontWeight: "bold",
        }}
        variant="caption"
      >
        Advertisement
      </Typography>
      <Adsense
        client="ca-pub-1112252263707173"
        slot="2473742529"
        style={{ display: "block" }}
        layout="in-article"
        format="fluid"
      />
      <Divider style={{ marginTop: 20, marginBottom: 20, width: "100%" }} />
    </Grid>
  );
}

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import client from "../../services/client";
import { USERS } from "../../services/services";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ViewMoreButton from "../../components/ViewMoreButton";
import Loader from "../../components/Loader";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 640,
    margin: 20,
  },
  list: {
    "&:hover": {
      cursor: "pointer",
      backgroundImage: `linear-gradient(135deg, #FF0076, #590FB7)`,
    },
    maxWidth: "100%",
  },
}));

export default function Leaderboard() {
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [sortBy, setSortBy] = useState("score");

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isAnnouncementShown = useSelector(
    (state) => state.announcement.isShown
  );

  const theme = useTheme();

  const username = localStorage.getItem("username");

  useEffect(() => {
    setUsers([]);
    setCurrentPage(1);
    setTotalCount(0);
  }, [sortBy]);

  useEffect(() => {
    if (users?.length === 0 && currentPage === 1 && totalCount === 0)
      fetchMoreData(sortBy === "upvotes" ? "-by-upvotes" : "");
  }, [sortBy, users, totalCount, currentPage]);

  useEffect(() => {
    if (username) {
      fetchUserData();
    }
  }, [username]);

  const fetchUserData = () => {
    setLoadingUserData(true);
    client
      .get(`${USERS}/${username}`)
      .then((res) => {
        setUserData(res.data.data);
        setLoadingUserData(false);
      })
      .catch((e) => {
        setLoadingUserData(false);
        enqueueSnackbar("Error loading your rank", {
          variant: "warning",
        });
      });
  };

  const fetchMoreData = (suffix = "") => {
    setLoading(true);
    setCurrentPage((prevState) => prevState + 1);
    client
      .get(`${USERS}/leaderboard${suffix}`, {
        params: { page: currentPage },
      })
      .then((res) => {
        setTotalCount(res.headers["total-count"]);

        setUsers((oldUsers) => {
          const newUniquePosts = res.data.data.filter(
            (post) =>
              !oldUsers.some((oldUsers) => oldUsers.username === post.username)
          );

          return [...oldUsers, ...newUniquePosts];
        });

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const generateRank = (num) => {
    return (
      <Grid item style={{ width: 40, textAlign: "center" }}>
        <text style={num < 4 ? { fontSize: 32 } : {}}>{generateNum(num)}</text>
      </Grid>
    );
  };

  const generateNum = (num) => {
    if (num === 1) return "🥇";
    if (num === 2) return "🥈";
    if (num === 3) return "🥉";
    return `#${num}`;
  };

  const generateGradient = (num) => {
    if (num === 1) return "#F06966, #FAD6A6";
    if (num === 2) return "#737DFE, #FFCAC9";
    if (num === 3) return "#FC6B03, #F14658";
    return "#D6FF7F, #00B3CC";
  };

  const generateTextStyle = (num) => {
    return {
      background: `-webkit-linear-gradient(135deg, ${generateGradient(num)}`,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    };
  };

  const handleOpenProfile = (username) => {
    window.open(`/profile/${username}`, "_blank").focus();
  };

  const kFormatter = (num) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  };

  const handleSort = (event, newSort) => {
    setSortBy(newSort);
  };

  const ogTitle = "Leaderboard | GAG101";

  return (
    <>
      <Helmet>
        <title>{ogTitle} | GAG101</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogTitle} />
        <meta property="og:image" content="https://gag101.com/logo.png" />
      </Helmet>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              backgroundImage: `linear-gradient(135deg, #FF0076, #590FB7)`,
            }}
          >
            <Grid container direction="row">
              <Grid item style={{ flex: 1 }}>
                <Typography variant="h6">Leaderboard</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 8,
              position: "sticky",
              top: isAnnouncementShown ? 108 : 72,
              zIndex: 99,
            }}
          >
            <Grid container justifyContent="center">
              <ToggleButtonGroup
                value={sortBy}
                exclusive
                onChange={handleSort}
                aria-label="text sort"
                size="small"
              >
                <ToggleButton
                  value="score"
                  aria-label="score"
                  style={
                    sortBy === "score"
                      ? {
                          backgroundImage: `linear-gradient(135deg, #FF0076, #590FB7)`,
                          border: 0,
                        }
                      : {
                          backgroundColor: theme.palette.background.default,
                          color: "white",
                        }
                  }
                >
                  <Typography variant="body2" display="inline">
                    Votes Score
                  </Typography>
                </ToggleButton>
                <ToggleButton
                  value="upvotes"
                  aria-label="upvotes"
                  style={
                    sortBy === "upvotes"
                      ? {
                          backgroundImage: `linear-gradient(135deg, #FF0076, #590FB7)`,
                          border: 0,
                        }
                      : {
                          backgroundColor: theme.palette.background.default,
                          color: "white",
                        }
                  }
                >
                  <Typography variant="body2" display="inline">
                    Total Upvotes
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              The leaderboard recognizes users who share funny and meme-worthy
              content, adding to the fun and enjoyment of the community. It
              ranks users based on the number of{" "}
              <b style={generateTextStyle(4)}>upvotes</b>{" "}
              {sortBy === "score" && (
                <>
                  minus <b style={generateTextStyle(3)}>downvotes</b>
                </>
              )}{" "}
              they receive on their <b style={generateTextStyle(1)}>posts</b>{" "}
              and <b style={generateTextStyle(1)}>comments</b>, showcasing their
              popularity and influence within the community.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              <b>IMPORTANT: </b>Any abuse of the leaderboard system may result
              in the reversal of upvotes and potential ban.
            </Typography>
          </Grid>
          {(userData || loadingUserData) && (
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  style={{
                    textAlign: "center",
                    backgroundColor: "#1D2236",
                    borderRadius: 20,
                    padding: "4px 8px",
                    marginBottom: 8,
                  }}
                >
                  <Typography
                    variant="body2"
                    display="inline"
                    style={{ color: "gray" }}
                  >
                    {loadingUserData ? (
                      "Loading your rank..."
                    ) : (
                      <>
                        You earned{" "}
                        <b style={{ color: "white" }}>
                          {kFormatter(userData?.total_upvotes)} upvotes
                        </b>
                        ,{" "}
                        <b style={{ color: "white" }}>
                          {kFormatter(userData?.total_votes_score)} votes score
                        </b>
                        , and are ranked{" "}
                        <b style={{ color: "white" }}>
                          #
                          {sortBy === "score"
                            ? userData?.leaderboard_rank
                            : userData?.leaderboard_rank_by_upvotes}
                        </b>
                      </>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: "#808080",
              position: "sticky",
              top: isAnnouncementShown ? 160 : 124,
              zIndex: 99,
              borderRadius: 20,
            }}
          >
            <Grid container>
              <Grid item style={{ width: 40, textAlign: "center" }}>
                <Typography variant="body2" display="inline">
                  Rank
                </Typography>
              </Grid>
              <Grid item style={{ marginLeft: 8 }}>
                <Typography variant="body2" display="inline">
                  User
                </Typography>
              </Grid>
              <Grid item style={{ flex: 1 }} />
              {sortBy === "upvotes" && (
                <Grid item style={{ width: 90 }}>
                  <Typography
                    variant="body2"
                    style={{
                      background:
                        "-webkit-linear-gradient(135deg, #FF0076, #590FB7)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      marginRight: 8,
                      width: 90,
                      textAlign: "center",
                    }}
                  >
                    Total Upvotes
                  </Typography>
                </Grid>
              )}
              {sortBy === "score" && (
                <Grid item style={{ width: 90 }}>
                  <Typography
                    variant="body2"
                    style={{
                      background:
                        "-webkit-linear-gradient(135deg, #FF0076, #590FB7)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      textAlign: "center",
                    }}
                  >
                    Votes Score
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              spacing={2}
              justifyContent="center"
            >
              {loading && users.length === 0 && <Loader />}
              {users.map((user, index) => (
                <Grid
                  item
                  className={classes.list}
                  onClick={() => handleOpenProfile(user?.username)}
                >
                  <Grid container alignItems="center" wrap="nowrap">
                    <Grid item>{generateRank(index + 1)}</Grid>
                    <Grid item style={{ marginRight: 8 }}>
                      <Avatar src={user?.avatar_thumb_url || AccountCircle} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        style={{
                          ...generateTextStyle(index + 1),
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {user?.username}
                      </Typography>
                    </Grid>
                    <Grid item style={{ flex: 1 }} />
                    {sortBy === "upvotes" && (
                      <Grid item style={{ width: 90 }}>
                        <Typography
                          variant="body2"
                          style={{
                            ...generateTextStyle(index + 1),
                            textAlign: "center",
                          }}
                        >
                          {kFormatter(user?.total_upvotes)}
                        </Typography>
                      </Grid>
                    )}
                    {sortBy === "score" && (
                      <Grid item style={{ width: 90 }}>
                        <Typography
                          variant="body2"
                          style={{
                            ...generateTextStyle(index + 1),
                            textAlign: "center",
                          }}
                        >
                          {kFormatter(user?.total_votes_score)}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {users?.length > 0 && users.length < parseInt(totalCount) && (
            <Grid item xs={12}>
              <ViewMoreButton
                text="users"
                loading={loading}
                onClick={() =>
                  fetchMoreData(sortBy === "upvotes" ? "-by-upvotes" : "")
                }
              />
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import client from "../services/client";
import { headersConfig } from "../services/headersConfig";
import { POSTS, USERS } from "../services/services";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function BlockDialog({
  openBlock,
  setOpenBlock,
  user_id,
  setOptionsOpen,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const handleBlock = () => {
    client
      .patch(`${USERS}/${user_id}/block`, null, {
        headers: {
          ...headersConfig,
        },
      })
      .then((res) =>
        enqueueSnackbar(
          "You will no longer see any post and comment from this person in the future" ||
            "Success",
          {
            variant: "success",
          }
        )
      )
      .catch((e) => {
        enqueueSnackbar(e.response?.data?.errors[0] || "Something went wrong", {
          variant: "warning",
        });
      });
    setOptionsOpen(false);
  };

  return (
    <>
      <Dialog
        open={openBlock}
        onClose={() => setOpenBlock(false)}
        aria-labelledby="reason-dialog"
      >
        <DialogTitle id="reason-dialog">Block</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to block this person?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenBlock(false)} color="primary">
            Close
          </Button>
          <Button onClick={handleBlock} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

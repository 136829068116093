import { Button, CircularProgress } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export default function ViewMoreButton({ loading, onClick, text = "posts" }) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        startIcon={
          loading ? (
            <CircularProgress size={12} style={{ marginRight: 8 }} />
          ) : (
            <ArrowDownwardIcon style={{ color: "#07f" }} />
          )
        }
        disabled={loading}
        variant="outlined"
        style={{
          color: "#07f",
          fontWeight: "bold",
          alignSelf: "center",
          marginBottom: 20,
        }}
        fullWidth
        onClick={onClick}
      >
        View more {text}
      </Button>
    </div>
  );
}

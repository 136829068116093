import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import client from "../services/client";
import { headersConfig } from "../services/headersConfig";
import { COMMENTS, POSTS } from "../services/services";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function MaxWidthDialog({
  openReport,
  setOpenReport,
  public_uid,
  comment_id,
  setOptionsOpen,
}) {
  const classes = useStyles();
  const [reason, setReason] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleReport = () => {
    const link = public_uid
      ? `${POSTS}/${public_uid}/report`
      : `${COMMENTS}/${comment_id}/report`;
    const type = public_uid ? "post" : "comment";

    client
      .patch(link, null, {
        params: {
          [`${type}[report_reason]`]: reason,
        },
        headers: {
          ...headersConfig,
        },
      })
      .then((res) =>
        enqueueSnackbar(res?.data?.message || "Success", {
          variant: "success",
        })
      )
      .catch((e) => {
        enqueueSnackbar(e.response?.data?.errors[0] || "Something went wrong", {
          variant: "warning",
        });
      });
    setOptionsOpen(false);
  };

  return (
    <>
      <Dialog
        open={openReport}
        onClose={() => setOpenReport(false)}
        aria-labelledby="reason-dialog"
      >
        <DialogTitle id="reason-dialog">Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choosing the right reason help us process the report as soon as
            possible.
          </DialogContentText>
          <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="reason">Reason</InputLabel>
              <Select
                autoFocus
                value={reason}
                onChange={handleReasonChange}
                inputProps={{
                  name: "reason",
                  id: "reason",
                }}
              >
                <MenuItem value="Spam">Politics</MenuItem>
                <MenuItem value="Spam">Spam</MenuItem>
                <MenuItem value="Repost">Repost</MenuItem>
                <MenuItem value="Pornography">Pornography</MenuItem>
                <MenuItem value="Hatred and bullying">
                  Hatred and bullying
                </MenuItem>
                <MenuItem value="Self-harm">Self-harm</MenuItem>
                <MenuItem value="Violent, gory and harmful content">
                  Violent, gory and harmful content
                </MenuItem>
                <MenuItem value="Deceptive content">Deceptive content</MenuItem>
                <MenuItem value="Copyright and trademark infringement">
                  Copyright and trademark infringement
                </MenuItem>
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenReport(false)} color="primary">
            Close
          </Button>
          <Button onClick={handleReport} disabled={!reason} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

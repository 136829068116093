import { Grid } from "@material-ui/core";
import {
  FacebookShareButton,
  LineShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LineIcon,
  PinterestIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

export default function Share({ post }) {
  const shareUrl = `https://gag101.com/posts/${post.public_uid}`;
  const media = post.image_url;
  const title = post.title;
  return (
    <Grid container direction="column">
      <FacebookShareButton url={shareUrl} title={title}>
        <FacebookIcon size={32} />
      </FacebookShareButton>
      <PinterestShareButton url={shareUrl} media={media} title={title}>
        <PinterestIcon size={32} />
      </PinterestShareButton>
      <LineShareButton url={shareUrl} media={media} title={title}>
        <LineIcon size={32} />
      </LineShareButton>
      <TelegramShareButton url={shareUrl} media={media} title={title}>
        <TelegramIcon size={32} />
      </TelegramShareButton>
      <TwitterShareButton url={shareUrl} media={media} title={title}>
        <TwitterIcon size={32} />
      </TwitterShareButton>
      <WhatsappShareButton url={shareUrl} media={media} title={title}>
        <WhatsappIcon size={32} />
      </WhatsappShareButton>
    </Grid>
  );
}

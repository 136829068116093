import { configureStore } from "@reduxjs/toolkit";
import announcementSlice from "./announcementSlice";
import modalReducer from "./modalSlice";

export default configureStore({
  reducer: {
    modal: modalReducer,
    announcement: announcementSlice,
  },
});

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import AdSide from "./AdSide";

const AdSideBar = ({ isDesktop }) => {
  const [hideAds, setHideAds] = useState(false);
  const [renderScreen, setRenderScreen] = useState(false);

  const location = useLocation();

  const locationToHide =
    location?.pathname.includes("settings") ||
    location?.pathname.includes("tnc") ||
    location?.pathname.includes("privacy") ||
    location?.pathname.includes("login") ||
    location?.pathname.includes("signup") ||
    location?.pathname.includes("upload") ||
    location?.pathname.includes("forgot-password");

  useEffect(() => {
    if (locationToHide) {
      setHideAds(true);
    } else {
      setHideAds(false);
    }
  }, [location]);

  useEffect(() => {
    if (location) {
      setRenderScreen(false);
      setTimeout(() => {
        setRenderScreen(true);
      }, 100);
    }
  }, [location]);

  if (!renderScreen) return null;

  if (isDesktop && !hideAds)
    return (
      <Grid
        item
        style={{
          marginTop: 120,
          marginLeft: 20,
          width: 300,
        }}
      >
        <Grid
          container
          direction="column"
          style={{ position: "sticky", top: 120 }}
        >
          <Typography
            style={{
              textAlign: "center",
              display: "block",
              marginBottom: 8,
              fontWeight: "bold",
            }}
            variant="caption"
          >
            Advertisement
          </Typography>
          <AdSide />
          <AdSide />
        </Grid>
      </Grid>
    );

  return null;
};

export default AdSideBar;

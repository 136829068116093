import React, { useEffect, useMemo, useState, Suspense } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import AppBar from "./components/AppBar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Container, Dialog, Grid, Slide, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import SideBar from "./components/SideBar";
import Drawer from "./components/Drawer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { SnackbarProvider } from "notistack";
import store from "./redux/store";
import { Provider, useDispatch, useSelector } from "react-redux";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { hide } from "./redux/modalSlice";

import MainRouter from "./MainRouter";
import Login from "./pages/login";
import AdSide from "./components/AdSide";
import AdSideBar from "./components/AdSideBar";

if (!window.location.href.includes("localhost")) {
  Sentry.init({
    dsn: "https://bfaf4aedf33b4762a029bc66bb1f459f@o936051.ingest.sentry.io/5960786",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

export default function AppWrapper() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

function App() {
  const [theme, setTheme] = useState("light");
  const [showSidebar, setShowSidebar] = useState(false);
  const muiTheme = useTheme();
  const isDesktop = useMediaQuery(muiTheme.breakpoints.up("md"));
  const showModal = useSelector((state) => state.modal.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDesktop) {
      setShowSidebar(true);
    }
  }, [isDesktop]);

  useEffect(() => {
    function checkTheme() {
      const item = localStorage.getItem("theme");

      if (item) {
        setTheme(localStorage.getItem(item));
      }
    }

    window.addEventListener("storage", checkTheme);

    return () => {
      window.removeEventListener("storage", checkTheme);
    };
  }, []);

  const _theme = useMemo(
    () =>
      createTheme({
        palette: {
          type: theme === "light" ? "light" : "dark",
        },
      }),
    [theme]
  );

  return (
    <ThemeProvider theme={_theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        TransitionComponent={Slide}
      >
        <Router>
          <AppBar
            theme={theme}
            setTheme={setTheme}
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
          />
          <Dialog
            open={showModal}
            onClose={() => dispatch(hide())}
            aria-labelledby="alert-dialog-login"
            aria-describedby="alert-dialog-login"
          >
            <Login isModal />
          </Dialog>
          <CssBaseline />
          <Container maxWidth="lg" disableGutters>
            <Grid container direction="row">
              <Grid item style={{ width: showSidebar && isDesktop ? 260 : 0 }}>
                <SideBar
                  display={showSidebar && isDesktop ? "block" : "none"}
                />
              </Grid>
              <Drawer
                showDrawer={showSidebar && !isDesktop}
                setShowDrawer={setShowSidebar}
              >
                <SideBar
                  display={showSidebar && !isDesktop ? "block" : "none"}
                  setShowSidebar={setShowSidebar}
                />
              </Drawer>
              <Grid
                item
                style={
                  !isDesktop
                    ? {
                        width: "100%",
                        marginTop: 120,
                      }
                    : !showSidebar
                    ? { margin: "120px auto" }
                    : { marginTop: 120 }
                }
              >
                <Suspense fallback={<div />}>
                  <MainRouter />
                </Suspense>
              </Grid>
              <AdSideBar isDesktop={isDesktop} />
            </Grid>
          </Container>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

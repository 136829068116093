import { Adsense } from "@ctrl/react-adsense";
import { Grid } from "@material-ui/core";

export default function AdSide() {
  return (
    <Grid
      item
      style={{
        marginBottom: 8,
      }}
    >
      <Adsense
        client="ca-pub-1112252263707173"
        slot="3216682822"
        style={{ display: "block" }}
        layout="in-article"
        format="fluid"
      />
    </Grid>
  );
}

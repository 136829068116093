import {
  Button,
  Grid,
  Divider,
  TextareaAutosize,
  IconButton,
  CircularProgress,
  Typography,
  Badge,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import client from "../services/client";
import { COMMENTS } from "../services/services";
import { useSnackbar } from "notistack";
import { headersConfig } from "../services/headersConfig";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Comment from "./Comment";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import GifIcon from "@material-ui/icons/Gif";
import TenorDialog from "./TenorDialog";

export default function Post({ commentId, postId, postUserId, userName }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [commentValue, setCommentValue] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const userId = localStorage.getItem("userId");
  const [newComments, setNewComments] = useState([]);
  const [openGifDialog, setOpenGifDialog] = useState(false);
  const [gifLink, setGifLink] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleFile = (event) => {
    if (event?.target?.files?.[0])
      return setSelectedFile(event.target.files[0]);
    if (event instanceof File) {
      setSelectedFile(event);
      console.log(event?.name);
      const _commentValue = commentValue.replace(event?.name, "");
      setTimeout(() => {
        setCommentValue(_commentValue);
      }, 0);
    } else {
      enqueueSnackbar("Something went wrong", {
        variant: "warning",
      });
    }
  };

  const handleGifClick = (item) => {
    setOpenGifDialog(false);
    setGifLink(item?.url);
    setPreview(item?.url);
  };

  useEffect(() => {
    const objectUrl = "";
    if (selectedFile) {
      // create the preview
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
    }
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    setCommentValue(`@${userName} `);
  }, [userName]);

  const handleSubmitComment = async () => {
    const token = await executeRecaptcha("postAComment");
    setLoadingSubmit(true);
    let formData = new FormData();
    formData.append("comment[body]", commentValue);
    formData.append("comment[post_id]", postId);
    formData.append("comment[parent_id]", commentId);
    formData.append("recaptcha_token", token);
    if (selectedFile) {
      formData.append("comment[image]", selectedFile);
    }
    if (gifLink) {
      formData.append("comment[gif_url]", gifLink);
    }

    client
      .post(COMMENTS, formData, { headers: headersConfig })
      .then((res) => {
        setNewComments((prevState) => [
          { ...res.data.data, is_downvoted: false, is_upvoted: false },
          ...prevState,
        ]);
        setLoadingSubmit(false);
        setSelectedFile(null);
        setGifLink(null);
        setCommentValue("");
        setPreview(null);
      })
      .catch((e) => {
        setLoadingSubmit(false);
        let errorMessage = "Something went wrong";
        const res = e.response?.data;
        try {
          errorMessage = res[Object.keys(res)[0]];
          errorMessage = errorMessage[0];
        } catch (e) {
          console.log(e);
        }
        enqueueSnackbar(errorMessage, {
          variant: "warning",
        });
      });
  };

  return (
    <Grid container direction="column" style={{ marginTop: 20, maxWidth: 590 }}>
      <Grid direction="column" container>
        {userId ? (
          <>
            <TextareaAutosize
              maxRows={4}
              minRows={4}
              aria-label="maximum height"
              placeholder="Write a comment..."
              onChange={(e) => setCommentValue(e.target.value)}
              value={commentValue}
              style={{
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                borderRadius: 4,
                padding: 8,
              }}
              onPasteCapture={(e) => {
                const clipboardItems = e.clipboardData.items;
                const items = [].slice
                  .call(clipboardItems)
                  .filter(function (item) {
                    // Filter the image items only
                    return /^image\//.test(item.type);
                  });
                if (items.length === 0) {
                  return;
                }

                const item = items[0];
                const blob = item.getAsFile();
                handleFile(blob);
              }}
            />
            {preview && (
              <Grid
                item
                style={{ marginTop: 16 }}
                onClick={() => {
                  setSelectedFile(null);
                  setPreview(null);
                }}
              >
                <Badge badgeContent={"x"} color="secondary" component={Button}>
                  <img
                    src={preview}
                    width="64"
                    height="64"
                    style={{ borderRadius: 4 }}
                  />
                </Badge>
              </Grid>
            )}
            <Grid container direction="row">
              <input
                accept="image/*"
                id={`icon-button-file-comment-${commentId}`}
                type="file"
                hidden
                onChange={handleFile}
                onClick={(event) => (event.target.value = null)}
              />
              <label htmlFor={`icon-button-file-comment-${commentId}`}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  style={{ marginTop: 2 }}
                >
                  <PhotoCamera />
                </IconButton>
              </label>
              <IconButton
                color="primary"
                aria-label="upload gif"
                component="span"
                onClick={() => setOpenGifDialog(true)}
              >
                <GifIcon />
              </IconButton>
              <TenorDialog
                open={openGifDialog}
                onGifClick={handleGifClick}
                onClose={() => setOpenGifDialog(false)}
              />
              <Grid item style={{ flex: 1 }} />
              <Button
                variant="outlined"
                style={{
                  margin: 8,
                  color: "gray",
                  fontWeight: "bold",
                  width: 48,
                }}
                disabled={!commentValue}
                onClick={handleSubmitComment}
              >
                {loadingSubmit ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Post"
                )}
              </Button>
            </Grid>
            <Divider style={{ marginBottom: 20 }} />
          </>
        ) : (
          <>
            <Typography>Log in to leave a comment</Typography>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          </>
        )}
        {newComments.map((comment, index) => (
          <Comment comment={comment} key={index} postUserId={postUserId} />
        ))}
      </Grid>
    </Grid>
  );
}

import {
  Button,
  Grid,
  Typography,
  Popover,
  Divider,
  TextareaAutosize,
  IconButton,
  CircularProgress,
  Collapse,
  Badge,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Share from "./Share";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ShareIcon from "@material-ui/icons/Share";
import client from "../services/client";
import { COMMENTS, POSTS } from "../services/services";
import { useSnackbar } from "notistack";
import { headersConfig } from "../services/headersConfig";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Comment from "./Comment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import BottomDrawer from "./BottomDrawer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Loader from "./Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch } from "react-redux";
import { show } from "../redux/modalSlice";
import { InView } from "react-intersection-observer";
import AdHorizontal from "./AdHorizontal";
import GifIcon from "@material-ui/icons/Gif";
import TenorDialog from "./TenorDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
  link: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  img: {
    maxWidth: "100%",
    height: "auto",
    marginBottom: -1,
  },
  padding: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  icon: { color: "white" },
}));

export default function Post({
  post,
  showThreads,
  setShowThreads,
  loadingThreads,
  threads,
  showComments,
  setShowComments,
  comments,
  fetchMoreComment,
  totalCount,
  loadingComments,
  loadingMoreComments,
  newComments,
  setNewComments,
  handlePrevPost,
  handleNextPost,
  loadingNext,
  loadingPrev,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [isUpvoted, setIsUpvoted] = useState(false);
  const [isDownvoted, setIsDownvoted] = useState(false);
  const [upvoteCount, setUpvoteCount] = useState(0);
  const [downvoteCount, setDownvoteCount] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [commentValue, setCommentValue] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const userId = localStorage.getItem("userId");
  const hideNsfw = localStorage.getItem("hideNsfw");
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [videoMuted, setVideoMuted] = useState(true);
  const [showDuration, setShowDuration] = useState(true);
  const [hideContent, setHideContent] = useState(false);
  const [openGifDialog, setOpenGifDialog] = useState(false);
  const [gifLink, setGifLink] = useState(null);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const linkOpt = !window.location.href.includes("posts") ? classes.link : "";

  const dispatch = useDispatch();

  useEffect(() => {
    setUpvoteCount(post.upvotes_count);
    setDownvoteCount(post.downvotes_count);
    setIsUpvoted(post.is_upvoted);
    setIsDownvoted(post.is_downvoted);
  }, [post]);

  useEffect(() => {
    if (post) {
      const containNsfw = post?.tag_list?.some(
        (item) => item.toLowerCase() === "nsfw".toLowerCase()
      );
      const isSensitive = post?.is_sensitive;

      if (hideNsfw === "false") return setHideContent(false);
      if (hideNsfw === null || hideNsfw || !userId) {
        setHideContent(containNsfw || isSensitive);
      }
    }
  }, [post, hideNsfw]);

  useEffect(() => {
    if (videoRef.current) {
      if (isVisible) {
        var promise = videoRef.current.play();

        if (promise !== undefined) {
          promise
            .then((_) => {
              setIsPaused(false);
            })
            .catch(() => {
              setIsPaused(true);
            });
        }
      } else {
        if (videoRef.current.play) {
          videoRef.current.pause();
          setIsPaused(true);
        }
      }
    }
  }, [videoRef, isVisible]);

  useEffect(() => {
    let timeout;

    if (isVisible) {
      timeout = setTimeout(() => {
        setShowDuration(false);
      }, 3000);
    } else {
      setShowDuration(true);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isVisible]);

  const handleClickShare = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenPost = () => {
    if (!window.location.href.includes("posts")) {
      window.open(`/posts/${post.public_uid}`, "_blank").focus();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpvote = (id) => {
    if (!localStorage.getItem("userId")) {
      dispatch(show());
      return;
    }
    const prevUpvote = upvoteCount;
    const prevDownvote = downvoteCount;
    if (isDownvoted) {
      setDownvoteCount((prevState) => prevState - 1);
      setIsDownvoted(false);
    }
    if (isUpvoted) {
      setUpvoteCount((prevState) => prevState - 1);
      setIsUpvoted(false);
      client
        .patch(`${POSTS}/${id}/unvote`, null, { headers: headersConfig })
        .catch((e) => {
          setUpvoteCount(prevUpvote);
          setIsUpvoted(true);
          enqueueSnackbar(e.response?.data?.message || "Something went wrong", {
            variant: "warning",
          });
        });
      return;
    }
    setUpvoteCount((prevState) => prevState + 1);
    setIsUpvoted(true);
    client
      .patch(`${POSTS}/${id}/upvote`, null, { headers: headersConfig })

      .catch((e) => {
        if (isDownvoted) {
          setDownvoteCount(prevDownvote);
          setIsDownvoted(true);
        }
        setUpvoteCount(prevUpvote);
        setIsUpvoted(false);
        enqueueSnackbar(e.response?.data?.message || "Something went wrong", {
          variant: "warning",
        });
      });
  };

  const handleDownvote = (id) => {
    if (!localStorage.getItem("userId")) {
      dispatch(show());
      return;
    }
    const prevUpvote = upvoteCount;
    const prevDownvote = downvoteCount;
    if (isUpvoted) {
      setUpvoteCount((prevState) => prevState - 1);
      setIsUpvoted(false);
    }
    if (isDownvoted) {
      setDownvoteCount((prevState) => prevState - 1);
      setIsDownvoted(false);
      client
        .patch(`${POSTS}/${id}/unvote`, null, { headers: headersConfig })
        .catch((e) => {
          setDownvoteCount(prevDownvote);
          setIsDownvoted(true);
          enqueueSnackbar(e.response?.data?.message || "Something went wrong", {
            variant: "warning",
          });
        });
      return;
    }
    setDownvoteCount((prevState) => prevState + 1);
    setIsDownvoted(true);
    client
      .patch(`${POSTS}/${id}/downvote`, null, { headers: headersConfig })
      .catch((e) => {
        if (isUpvoted) {
          setUpvoteCount(prevUpvote);
          setIsUpvoted(true);
        }
        setDownvoteCount(prevDownvote);
        setIsDownvoted(false);
        enqueueSnackbar(e.response?.data?.message || "Something went wrong", {
          variant: "warning",
        });
      });
  };

  const handleFile = (event) => {
    if (event?.target?.files?.[0])
      return setSelectedFile(event.target.files[0]);
    if (event instanceof File) {
      setSelectedFile(event);
      const _commentValue = commentValue.replace(event?.name, "");
      setTimeout(() => {
        setCommentValue(_commentValue);
      }, 0);
    } else {
      enqueueSnackbar("Something went wrong", {
        variant: "warning",
      });
    }
  };

  const handleShowContent = () => {
    if (!localStorage.getItem("userId")) {
      dispatch(show());
      return;
    }
    setHideContent(false);
  };

  useEffect(() => {
    const objectUrl = "";
    if (selectedFile) {
      // create the preview
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
    }
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleSubmitComment = async () => {
    setLoadingSubmit(true);
    const token = await executeRecaptcha("postAComment");
    let formData = new FormData();
    formData.append("comment[body]", commentValue);
    formData.append("comment[post_id]", post.id);
    formData.append("recaptcha_token", token);
    if (selectedFile) {
      formData.append("comment[image]", selectedFile);
    }
    if (gifLink) {
      formData.append("comment[gif_url]", gifLink);
    }

    client
      .post(COMMENTS, formData, { headers: headersConfig })
      .then((res) => {
        setNewComments((prevState) => [
          { ...res.data.data, is_downvoted: false, is_upvoted: false },
          ...prevState,
        ]);
        setLoadingSubmit(false);
        setSelectedFile(null);
        setGifLink(null);
        setPreview(null);
        setCommentValue("");
      })
      .catch((e) => {
        let errorMessage = "Something went wrong";
        const res = e.response?.data;
        try {
          errorMessage = res[Object.keys(res)[0]];
          errorMessage = errorMessage[0];
        } catch (e) {
          console.log(e);
        }
        enqueueSnackbar(errorMessage, {
          variant: "warning",
        });
        setLoadingSubmit(false);
      });
  };

  const handleClickVideo = () => {
    if (isPaused) {
      setIsPaused(false);
      videoRef?.current?.play();
    } else {
      videoRef.current.pause();
      setIsPaused(true);
    }
  };

  const handleClickMuteVideo = () => {
    if (videoMuted) {
      setVideoMuted(false);
    } else {
      setVideoMuted(true);
    }
  };

  const handleGifClick = (item) => {
    setOpenGifDialog(false);
    setGifLink(item?.url);
    setPreview(item?.url);
  };

  const open = Boolean(anchorEl);

  if (deleted)
    return (
      <div style={{ backgroundColor: "#999", marginTop: 20, padding: 4 }}>
        Post deleted
      </div>
    );

  return (
    <Grid container direction="column">
      {/* caption */}
      <Grid item className={classes.padding}>
        <Grid container direction="row" alignItems="center">
          <Grid item style={{ marginRight: 4 }}>
            <Typography
              variant="caption"
              display="block"
              style={{ color: "#999" }}
              onClick={() => history.push(`/section/${post?.section?.slug}`)}
              className={classes.link}
            >
              #{post?.section?.name}{" "}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="caption"
              display="block"
              style={{ color: "#999" }}
            >
              · {moment(post.created_at).fromNow()}
            </Typography>
          </Grid>
          <Grid item style={{ flex: 1 }} />
          <Grid item>
            <IconButton
              size="small"
              aria-label="options"
              onClick={() => setOptionsOpen(true)}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {/* caption end */}
      {/* title */}
      <Grid item className={classes.padding}>
        <Typography
          variant="h5"
          gutterBottom
          onClick={handleOpenPost}
          className={linkOpt}
          style={{ maxWidth: 640, wordBreak: "break-word" }}
        >
          {post.title}
        </Typography>
      </Grid>
      {/* title end*/}
      {/* image */}
      {hideContent && (
        <Grid item style={{ overflow: "hidden" }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            spacing={1}
            style={{
              height: 200,
            }}
          >
            <Grid item>
              <Typography
                variant="h5"
                display="block"
                onClick={() => setHideContent(false)}
                className={classes.notSafe}
              >
                Sensitive content
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="caption"
                display="block"
                className={classes.notSafe}
              >
                The following media includes potentially sensitive content.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="caption"
                display="block"
                className={classes.notSafe}
              >
                You can change your preference in the setting.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleShowContent}
              >
                Show
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Collapse in={!hideContent}>
        {!hideContent && (
          <Grid
            container
            style={{
              width: isDesktop ? 640 : "100%",
              flex: 1,
              backgroundColor:
                localStorage.getItem("theme") === "light"
                  ? "#f4f4f4"
                  : "hsla(0,0%,100%,.1)",
              marginBottom: 20,
              justifyContent: "center",
              position: "relative",
            }}
          >
            {post.image_url ? (
              <picture style={{ margin: "auto", marginBottom: -5 }}>
                <LazyLoadImage
                  alt={post.title}
                  height="auto"
                  src={post.image_url}
                  effect="opacity"
                  className={`${linkOpt} ${classes.img}`}
                  onClick={handleOpenPost}
                />
              </picture>
            ) : (
              <InView
                as="div"
                onChange={(inView, entry) => setIsVisible(inView)}
                threshold={0.7}
              >
                <>
                  <video
                    ref={videoRef}
                    muted={videoMuted}
                    preload="auto"
                    poster={post.video_thumb_url}
                    loop
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      maxHeight: isDesktop ? 480 : 320,
                    }}
                    onClick={handleClickVideo}
                  >
                    <source src={post.video_url} type="video/mp4" />
                  </video>
                  {videoRef.current && (
                    <>
                      {showDuration && !isNaN(videoRef?.current?.duration) && (
                        <Typography
                          style={{
                            position: "absolute",
                            right: 16,
                            bottom: 16,
                            borderRadius: 12,
                            backgroundColor: "rgba(0,0,0,.8)",
                            padding: "0 8px",
                            color: "white",
                          }}
                        >
                          {Math.floor(videoRef?.current?.duration / 60) +
                            ":" +
                            (
                              "0" + Math.floor(videoRef?.current?.duration % 60)
                            ).slice(-2)}
                        </Typography>
                      )}
                      <div
                        style={{
                          position: "absolute",
                          left: 16,
                          bottom: 16,
                          borderRadius: 15,
                          backgroundColor: "rgba(0,0,0,.8)",
                          padding: "0 8px",
                          width: 30,
                          height: 30,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={handleClickMuteVideo}
                      >
                        {videoMuted ? (
                          <VolumeOffIcon className={classes.icon} />
                        ) : (
                          <VolumeUpIcon className={classes.icon} />
                        )}
                      </div>
                      {isPaused && (
                        <div
                          style={{
                            position: "absolute",
                            marginLeft: "auto",
                            marginRight: "auto",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            borderRadius: 30,
                            backgroundColor: "rgba(0,0,0,.8)",
                            padding: "0 8px",
                            width: 50,
                            height: 50,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={handleClickVideo}
                        >
                          <PlayArrowIcon
                            fontSize="large"
                            className={classes.icon}
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              </InView>
            )}
          </Grid>
        )}
      </Collapse>
      {/* image end */}
      {/* tags */}
      {window.location.href.includes("posts") && (
        <Grid
          container
          direction="row"
          alignContent="space-around"
          className={classes.padding}
        >
          {post.tag_list.map((tag) => (
            <Button
              variant="outlined"
              size="small"
              style={{
                marginRight: 8,
                marginBottom: 12,
              }}
              onClick={() => history.push(`/tag/${tag}`)}
            >
              {tag}
            </Button>
          ))}
        </Grid>
      )}
      {/* tags end */}
      {/* button */}
      <Grid
        container
        direction="row"
        alignContent="space-around"
        className={classes.padding}
      >
        <Button
          startIcon={
            <ArrowUpwardIcon style={{ color: isUpvoted ? "#07f" : "gray" }} />
          }
          variant="outlined"
          style={{
            marginRight: 8,
            color: isUpvoted ? "#07f" : "gray",
            fontWeight: "bold",
            backgroundColor: isUpvoted ? "rgba(0,119,255,.12)" : "inherit",
            borderColor: isUpvoted ? "rgba(0,119,255,.2)" : "inherit",
          }}
          onClick={() => handleUpvote(post.public_uid)}
        >
          {upvoteCount}
        </Button>
        <Button
          startIcon={
            <ArrowDownwardIcon
              style={{
                color: isDownvoted ? "#07f" : "gray",
              }}
            />
          }
          variant="outlined"
          style={{
            marginRight: 8,
            color: isDownvoted ? "#07f" : "gray",
            fontWeight: "bold",
            backgroundColor: isDownvoted ? "rgba(0,119,255,.12)" : "inherit",
            borderColor: isDownvoted ? "rgba(0,119,255,.2)" : "inherit",
          }}
          onClick={() => handleDownvote(post.public_uid)}
        >
          {downvoteCount}
        </Button>
        <Button
          startIcon={
            <ChatBubbleIcon style={{ color: "gray", width: 15, height: 15 }} />
          }
          variant="outlined"
          style={{ color: "gray", fontWeight: "bold" }}
          onClick={handleOpenPost}
        >
          {post.comments_count}
        </Button>
        <Grid item style={{ flex: 1 }} />
        <Button
          startIcon={<ShareIcon style={{ color: "gray", marginRight: -12 }} />}
          variant="outlined"
          style={{ color: "gray", minWidth: 48 }}
          onClick={handleClickShare}
          aria-label="share-button"
        />
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
          open={open}
          classes={{
            paper: classes.paper,
          }}
        >
          <Share post={post} />
        </Popover>
      </Grid>
      {window.location.href.includes("posts") && (
        <Grid
          container
          direction="row"
          alignContent="space-around"
          className={classes.padding}
          style={{ marginTop: 20 }}
        >
          <Button
            startIcon={
              <ArrowBackIcon
                style={{ color: "white", width: 15, height: 15 }}
              />
            }
            variant="contained"
            color="secondary"
            disableElevation
            disabled={loadingPrev}
            style={{
              color: "white",
              fontWeight: "bold",
            }}
            onClick={handlePrevPost}
          >
            Prev
          </Button>
          <Grid item style={{ flex: 1 }} />
          <Button
            endIcon={
              <ArrowForwardIcon
                style={{ color: "white", width: 15, height: 15 }}
              />
            }
            variant="contained"
            color="primary"
            disableElevation
            disabled={loadingNext}
            style={{
              color: "white",
              fontWeight: "bold",
            }}
            onClick={handleNextPost}
          >
            Next
          </Button>
        </Grid>
      )}
      {window.location.href.includes("posts") && <AdHorizontal />}
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      {comments && (
        <Grid direction="column" container style={{ maxWidth: 640 }}>
          {userId ? (
            <>
              <TextareaAutosize
                maxRows={4}
                minRows={4}
                aria-label="maximum height"
                placeholder="Write a comment..."
                onChange={(e) => setCommentValue(e.target.value)}
                value={commentValue}
                style={{
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  borderRadius: 4,
                  padding: 8,
                }}
                onPasteCapture={(e) => {
                  const clipboardItems = e.clipboardData.items;
                  const items = [].slice
                    .call(clipboardItems)
                    .filter(function (item) {
                      // Filter the image items only
                      return /^image\//.test(item.type);
                    });
                  if (items.length === 0) {
                    return;
                  }

                  const item = items[0];
                  const blob = item.getAsFile();
                  handleFile(blob);
                }}
              />
              {preview && (
                <Grid
                  item
                  style={{ marginTop: 16 }}
                  onClick={() => {
                    setSelectedFile(null);
                    setPreview(null);
                  }}
                >
                  <Badge
                    badgeContent={"x"}
                    color="secondary"
                    component={Button}
                  >
                    <img
                      src={preview}
                      width="64"
                      height="64"
                      style={{ borderRadius: 4 }}
                    />
                  </Badge>
                </Grid>
              )}
              <Grid container direction="row">
                <input
                  accept="image/*"
                  id="icon-button-file-post"
                  type="file"
                  hidden
                  onChange={handleFile}
                  onClick={(event) => (event.target.value = null)}
                />
                <label htmlFor="icon-button-file-post">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    style={{ marginTop: 2 }}
                  >
                    <PhotoCamera />
                  </IconButton>
                </label>
                <IconButton
                  color="primary"
                  aria-label="upload gif"
                  component="span"
                  onClick={() => setOpenGifDialog(true)}
                >
                  <GifIcon />
                </IconButton>
                <TenorDialog
                  open={openGifDialog}
                  onGifClick={handleGifClick}
                  onClose={() => setOpenGifDialog(false)}
                />
                <Grid item style={{ flex: 1 }} />
                <Button
                  variant="outlined"
                  style={{
                    margin: 8,
                    color: "gray",
                    fontWeight: "bold",
                    width: 48,
                  }}
                  onClick={handleSubmitComment}
                >
                  {loadingSubmit ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Post"
                  )}
                </Button>
              </Grid>
              <Divider style={{ marginBottom: 20 }} />
            </>
          ) : (
            <>
              <Typography className={classes.padding}>
                Log in to leave a comment
              </Typography>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </>
          )}
          {newComments.map((comment, index) => (
            <Comment comment={comment} key={index} postUserId={post.user_id} />
          ))}
          {showThreads && threads && !showComments && (
            <Comment
              comment={threads}
              key={0}
              postUserId={threads?.user?.ud}
              loadingThreads={loadingThreads}
            />
          )}
          {showComments ? (
            !loadingComments ? (
              comments.map((comment, index) => (
                <Comment
                  comment={comment}
                  key={index}
                  postUserId={post.user_id}
                />
              ))
            ) : (
              <Loader />
            )
          ) : (
            <Button
              variant="outlined"
              style={{
                margin: 8,
                color: "gray",
                fontWeight: "bold",
              }}
              onClick={() => {
                setShowComments(true);
                setShowThreads(false);
              }}
            >
              Show all comments
            </Button>
          )}
          {comments.length < totalCount && showComments && (
            <Button
              variant="outlined"
              style={{
                margin: 8,
                color: "gray",
                fontWeight: "bold",
              }}
              onClick={fetchMoreComment}
              disabled={loadingMoreComments}
            >
              {loadingMoreComments ? (
                <CircularProgress size={24} />
              ) : (
                "More Comments"
              )}
            </Button>
          )}
        </Grid>
      )}
      {optionsOpen && (
        <BottomDrawer
          optionsOpen={optionsOpen}
          setOptionsOpen={setOptionsOpen}
          downloadLink={post?.image_original_url || post?.video_url}
          public_uid={post?.public_uid}
          setDeleted={setDeleted}
          user_id={post?.user_id}
        />
      )}
    </Grid>
  );
}

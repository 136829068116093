import { useEffect, useState } from "react";
import Post from "./Post";
import client from "../services/client";
import { POSTS } from "../services/services";
import InfiniteScroll from "react-infinite-scroll-component";
import { headersConfig } from "../services/headersConfig";
import Loader from "./Loader";
import { Helmet } from "react-helmet";
import { Button, CircularProgress } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ViewMoreButton from "./ViewMoreButton";
import AdSquare from "./AdSquare";

export default function PostList({ params = {}, pageTitle, pageDesc }) {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchMoreData();
  }, []);

  const fetchMoreData = () => {
    setLoading(true);
    setCurrentPage((prevState) => prevState + 1);
    client
      .get(POSTS, {
        params: { page: currentPage, ...params },
        headers: headersConfig,
      })
      .then((res) => {
        setTotalCount(res.headers["total-count"]);

        setPosts((oldPosts) => {
          const newUniquePosts = res.data.data.filter(
            (post) => !oldPosts.some((oldPost) => oldPost.id === post.id)
          );

          return [...oldPosts, ...newUniquePosts];
        });

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (posts.length === 0 && !loading)
    return (
      <img
        src="/nothing-to-see-here-move-along.png"
        style={{ maxWidth: "100%", height: "auto" }}
      />
    );

  const ogTitle = pageTitle || "GAG101 | Meme and Rage Comic";
  const ogDesc = pageDesc || "Meme and Rage Comic";
  return (
    <>
      <Helmet>
        <title>{ogTitle}</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDesc} />
        <meta property="og:image" content="https://gag101.com/logo.png" />
      </Helmet>
      <InfiniteScroll
        dataLength={posts.length}
        next={fetchMoreData}
        hasMore={posts.length < parseInt(totalCount)}
        loader={<h4>Loading...</h4>}
        endMessage={
          loading ? (
            <Loader />
          ) : (
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          )
        }
      >
        {posts.map((item, index) => (
          <>
            <Post post={item} key={index} />
            {(index + 1) % 5 == 0 && <AdSquare />}
          </>
        ))}
        {/* {posts?.length > 0 && posts.length < parseInt(totalCount) && (
          <ViewMoreButton loading={loading} onClick={fetchMoreData} />
        )} */}
      </InfiniteScroll>
    </>
  );
}

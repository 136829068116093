const URL = "https://backend.gag101.com"
const BASE_URL = `${URL}/api/v1`;
const LOGIN = "/auth/sign_in?";
const RESET_PASSWORD = "/auth/password?";
const REGISTER = "/auth";
const SECTIONS = "/sections";
const POSTS = "/posts";
const COMMENTS = "/comments";
const USERS = "/users";
const INITIAL_DATA = "/users/initial-data";
const BADGES = "/badges";
const CHANGE_BADGE = "/users/update-main-badge";
const NOTIFICATIONS = "/notifications";
const MARK_ALL_NOTIF = "/notifications/mark-all-as-read";
const CHANGE_PASSWORD = "/auth/password";
const SAVED_POST = "/users/saved-posts";
const RECAPTCHA_SITE_KEY_V3 = "6LeVH9sbAAAAAAIVhCO6yo3p4zGcUMxrIKhCXEhW";

export {
  URL,
  BASE_URL,
  LOGIN,
  REGISTER,
  SECTIONS,
  POSTS,
  USERS,
  INITIAL_DATA,
  CHANGE_PASSWORD,
  SAVED_POST,
  COMMENTS,
  NOTIFICATIONS,
  RECAPTCHA_SITE_KEY_V3,
  BADGES,
  CHANGE_BADGE,
  RESET_PASSWORD,
  MARK_ALL_NOTIF,
};

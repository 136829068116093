import LoginForm from "./LoginForm";
import { useSnackbar } from "notistack";
import client from "../../services/client";
import { LOGIN } from "../../services/services";
import { Mixpanel } from "../../services/mixpanel";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";

export default function Login({ isModal }) {
  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (values, setSubmitting) => {
    const token = await executeRecaptcha("signIn");
    client
      .post(LOGIN, {
        email: values.email,
        password: values.password,
        recaptcha_token: token,
      })
      .then((res) => {
        const user = res.data.data;

        Mixpanel.identify(user.id);
        Mixpanel.track("Successful sign in");
        Mixpanel.people.set({
          $email: values.email,
        });

        setSubmitting(false);
        localStorage.setItem(
          "auth",
          JSON.stringify(res.headers, { id: user.id })
        );
        localStorage.setItem("userId", user.id);
        localStorage.setItem("username", user.username);
        window.location.href = "/";
      })
      .catch((e) => {
        Mixpanel.track("Unsuccessful sign in");

        enqueueSnackbar(e.response?.data?.errors[0] || "Something went wrong", {
          variant: "warning",
        });
        setSubmitting(false);
      });
  };

  const ogTitle = 'Log In | GAG101';
  return (
    <>
      <Helmet>
        <title>{ogTitle}</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content="Log In" />
        <meta property="og:image" content="https://gag101.com/logo.png" />
      </Helmet>
      <LoginForm handleSubmit={onSubmit} isModal={isModal} />
    </>
  );
}
